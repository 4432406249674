import React, { useEffect, useContext } from 'react'; 
import Accordion from 'react-bootstrap/Accordion'; 
import {Context} from '../Store';
//import { useWeb3React } from "@web3-react/core";
import { Buffer } from "buffer";

import { Layout } from "antd";

import {Link, useParams } from "react-router-dom";
import background from "./../assets/images/background.jpg";
import web3Boilerplate_logo from "./../assets/images/logo.png";
import ChainSelector from "../components/ChainSelector";
 
import ".././App.css";
import { useWindowWidthAndHeight } from "../hooks/useWindowWidthAndHeight";
 
  
import IntroBoard from './private/IntroBoard';
import Header from './private/Header';
 

 
const TemplateLayout = ({children}) => {
  if (!window.Buffer) window.Buffer = Buffer;
  const [state] = useContext(Context);
  let params = useParams();  
   

   


  return (

    <> 

<Header />
     
{children}
 
     <footer className="footer">
       <div className="shape" />
       <div className="container">
         <div className="row">
           <div className="col-md-12">
             <div className="footer__main">
               <div className="block-text center">
                 <h3 className="heading"></h3>
                 <p></p>
               </div>
                 
             </div>
             <div className="footer__bottom">
               <a href="/" className="logo">
                 Dogetagold
               </a>
               <div className="center mb--30">
                 <ul className="list">
                   <li>
                     <a href="/">Home</a>
                   </li>
                   <li>
                     <a href="/mine">Mine Token</a>
                   </li>
                   <li>
                     <a href="/convert">Convert</a>
                   </li>
                   
                   <li>
                     <a href="/swap">Swap</a>
                   </li>
                    
                 </ul>
                 <p>E-Mail: support@dogetagold.com</p>
                 <p>Copyright &copy; Dogetagold</p>
               </div>
                
             </div>
           </div>
         </div>
       </div>
     </footer>
    
  </>
 
  );
}

export const Logo = () => {
  const [width] = useWindowWidthAndHeight();
  const isMobile = width <= 768;
  return (
    <>
      {isMobile ? (
        <div style={{ paddingTop: "10px"}}>
         <h3><img src={web3Boilerplate_logo} alt="metegan" width="30px" style={{borderRadius: "20px"}} /> <b  style={{color: "white"}}>Dogetagold</b></h3> 
        </div>
      ) : (
        <div style={{ paddingTop: "5px" }}>
          <h3><img src={web3Boilerplate_logo} alt="metegan" width="30px" style={{borderRadius: "20px"}} /> <b  style={{color: "white"}}>Dogetagold</b></h3> 
         </div>
      )}
    </>
  );
};

export default TemplateLayout;
